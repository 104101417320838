
/*
// ██████╗  █████╗ ████████╗ █████╗ 
// ██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗
// ██║  ██║███████║   ██║   ███████║
// ██║  ██║██╔══██║   ██║   ██╔══██║
// ██████╔╝██║  ██║   ██║   ██║  ██║
// ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝
*/

export const toSlug = (s: string, delimiter:string='_') => s.replace(/\s/ig, delimiter).toLowerCase()
export const tuple = <T = string>(...args: T[]) => args;

/*
// ██╗      ██████╗  ██████╗ █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
// ██║     ██╔═══██╗██╔════╝██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
// ██║     ██║   ██║██║     ███████║   ██║   ██║██║   ██║██╔██╗ ██║
// ██║     ██║   ██║██║     ██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
// ███████╗╚██████╔╝╚██████╗██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
// ╚══════╝ ╚═════╝  ╚═════╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
*/

export type MappedKey<T> = keyof T
export type MappedValue<T> = T[keyof T]
export type New_Zealand_State = MappedKey<typeof New_Zealand_States>
export type Australia_State = MappedKey<typeof Australia_States>
export type New_Zealand_StateKey = MappedValue<typeof New_Zealand_States>
export type Australia_StateKey = MappedValue<typeof Australia_States>
export type State = New_Zealand_State | Australia_State
export type StateKey = New_Zealand_StateKey | Australia_StateKey
export const New_Zealand_States = {
    'Auckland':      'nz.auckland',
    'Christchurch':  'nz.christchurch',
    'Queenstown':    'nz.queenstown',
 } as const
export const Australia_States = {
    'Adelaide':      'au.adelaide',
    'Alice Springs': 'au.alice_springs',
    'Airlie Beach':  'au.airlie_beach',
    'Brisbane':      'au.brisbane',
    'Byron Bay':     'au.byron_bay',
    'Cairns':        'au.cairns',
    'Darwin':        'au.darwin',
    'Gold Coast':    'au.gold_coast',
    'Hobart':        'au.hobart',
    'Perth':         'au.perth',
    'Melbourne':     'au.melbourne',
    'Sydney':        'au.sydney',
} as const
type CountryCode = "AU" | "NZ"
export type DestinationCurrency = 'AUD'|'NZD'
type LocationData = {
    key: string,
    nice: string,
    state: State,
    country: CountryCode,
}

export const All_States: LocationData[] = Object.entries({
    'NZ': New_Zealand_States,
    'AU': Australia_States,
} as Record<CountryCode, Record<State,StateKey>>).flatMap(([country, states]:[CountryCode, Record<State,StateKey>]) => {
    return Object.entries(states).map(([state,key]:[State,StateKey]) => ({
        // key: toSlug(`${country}.${state}`),
        key,
        nice: `${state}, ${country}`,
        state,
        country,
    }))
}) // [{ key:'au.byron_bay', nice:'Byron Bay, AU', state, country }]


export function isNZ(l: string): l is New_Zealand_State {
    return Object.values(New_Zealand_States).includes(l as any)
}
export function isAustralia(l: string): l is Australia_State {
    return Object.values(Australia_States).includes(l as any)
}
export function isState(l: string): l is State {
    return isNZ(l) || isAustralia(l)
}
export function isLocationKey(l: string): l is State {
    return All_States.some(({ key }) => key == l)
}
export function getLocationByKey(l: string): LocationData {
    return All_States.find(({ key }) => key == l)
}

/*
// ███████╗████████╗ █████╗ ████████╗██╗   ██╗███████╗
// ██╔════╝╚══██╔══╝██╔══██╗╚══██╔══╝██║   ██║██╔════╝
// ███████╗   ██║   ███████║   ██║   ██║   ██║███████╗
// ╚════██║   ██║   ██╔══██║   ██║   ██║   ██║╚════██║
// ███████║   ██║   ██║  ██║   ██║   ╚██████╔╝███████║
// ╚══════╝   ╚═╝   ╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚══════╝
*/

export const StatusList = {
    'Pending': 'pending',
    'Requote': 'requote',
    'Expired': 'expired',
    'Booked': 'booked',
    'Deleted': 'deleted',
}
export const Statuses = tuple(...Object.values(StatusList))
export const StatusesNice = tuple(...Object.keys(StatusList))
export type Status = typeof Statuses[number]

export function isStatus(s: string): s is Status {
    return Statuses.includes(s as any)
}

export const HR = 60 * 60 * 1000
export const DAY = 24 * HR
export const DEFAULT_DISCOUNT_WINDOW = 7 * DAY

/*
//  ██████╗ ██████╗ ████████╗██╗ ██████╗ ███╗   ██╗███████╗
// ██╔═══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
// ██║   ██║██████╔╝   ██║   ██║██║   ██║██╔██╗ ██║███████╗
// ██║   ██║██╔═══╝    ██║   ██║██║   ██║██║╚██╗██║╚════██║
// ╚██████╔╝██║        ██║   ██║╚██████╔╝██║ ╚████║███████║
//  ╚═════╝ ╚═╝        ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

// Amenity

export const NoToiletShower = `No Toilet & Shower`
export const ToiletNoShower = `Toilet* & No Shower`
export const ToiletShower = `Toilet & Shower`
export const AmenityRequested = `Requested with company`
export const Amenity = [
    NoToiletShower,
    ToiletNoShower,
    ToiletShower,
    AmenityRequested,
] as const
export type AmenityOption = typeof Amenity[number]

// Fridge

export const Fridge = `Fridge`
export const NoFridge = `No fridge`
export const FridgeOptions = [
    Fridge,
    NoFridge,
] as const
export type FridgeOption = typeof FridgeOptions[number]

// Transmission

export const Automatic = 'Automatic'
export const Manual = 'Manual'
export const TransmissionOptions = [
    Automatic,
    Manual,
] as const
export type TransmissionOption = typeof TransmissionOptions[number]

// SpecOptions


export const SpecOptionsList = {
    'Auto': 'auto',
    'Toilet & Shower': 'toilet_shower',
    'Fridge': 'fridge',
    'Anchor Points: 1': 'anchor_1',
    'Anchor Points: 2': 'anchor_2',
}
export const SpecOptionsNice = tuple(...Object.keys(SpecOptionsList))
export const SpecOptionsSlug = tuple(...Object.values(SpecOptionsList))
export type SpecOptionNice = typeof SpecOptionsNice[number]
export type SpecOptionSlug = typeof SpecOptionsSlug[number]
export const optionList = Object.entries(SpecOptionsList).map(([nice, slug]) => ({ slug, nice }))

/*
anchor_points:_1: true
anchor_points:_2: true
auto: true
fridge: true
toilet_&_shower: true
*/

/*
// ████████╗██╗   ██╗██████╗ ███████╗███████╗
// ╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝██╔════╝
//    ██║    ╚████╔╝ ██████╔╝█████╗  ███████╗
//    ██║     ╚██╔╝  ██╔═══╝ ██╔══╝  ╚════██║
//    ██║      ██║   ██║     ███████╗███████║
//    ╚═╝      ╚═╝   ╚═╝     ╚══════╝╚══════╝
*/
export const VehicleTypeList = {
    Sleeper: 'sleeper',
    "Low Top": 'low_top',
    "Hi Top": 'hi_top',
    Motorhome: 'motorhome',
    '4x4': '4x4',
}


export const VehicleTypes = tuple(...Object.values(VehicleTypeList))
export const VehicleTypesNice = tuple(...Object.keys(VehicleTypeList))
//     'Sleeper', // Sleep
//     'Low Top', // LT
//     'Hi Top', // HT
//     'Motorhome', // MH
//     '4x4'      , // 4x4
// )
export type VehicleType = typeof VehicleTypes[number]
export type VehicleTypeNice = typeof VehicleTypesNice[number]
export const typeList = VehicleTypesNice.map(vehicleType => ({
    slug: toSlug(vehicleType),
    nice: vehicleType,
}))

export const BedTypeList = {
    '1 Bed': 1,
    '1 Bed + Tent': 1.5,
    '2 Beds': 2,
    '2 Beds + Tent': 2.5,
    '3 Beds': 3,
    '4 Beds': 4,
} as const
export const BedTypesNumeric = Object.values(BedTypeList) as BedTypeNumeric[]
export const BedTypes = Object.keys(BedTypeList) as BedType[]
export type BedTypeNumeric = typeof BedTypeList[BedType]
export type BedType = keyof typeof BedTypeList

export const NEWEST_MODEL = `Newest Model`
export const NEWER_MODEL = 'Newer Model'
export const BUDGET_MODEL = `Budget Model`
export const OLDER_MODEL = `Older Model`
export const OLD_APOLLO_MODEL = `Old Apollo Model`
export const OLD_CHEAPA_MODEL = `Old Cheapa Model`
export const OLD_BRITZ_MODEL = `Old Britz Model`
export const OLD_MAUI_MODEL = `Old Maui Model`
export const NewnessOptions = [
    NEWEST_MODEL,
    NEWER_MODEL,
    BUDGET_MODEL,
    OLDER_MODEL,
    OLD_APOLLO_MODEL,
    OLD_CHEAPA_MODEL,
    OLD_BRITZ_MODEL,
    OLD_MAUI_MODEL,
] as const
export type NewnessOption = typeof NewnessOptions[number]

/*
//  ██████╗ ██████╗ ███████╗██████╗  █████╗ ████████╗ ██████╗ ██████╗ ███████╗
// ██╔═══██╗██╔══██╗██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔═══██╗██╔══██╗██╔════╝
// ██║   ██║██████╔╝█████╗  ██████╔╝███████║   ██║   ██║   ██║██████╔╝███████╗
// ██║   ██║██╔═══╝ ██╔══╝  ██╔══██╗██╔══██║   ██║   ██║   ██║██╔══██╗╚════██║
// ╚██████╔╝██║     ███████╗██║  ██║██║  ██║   ██║   ╚██████╔╝██║  ██║███████║
//  ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚══════╝
*/

// import { australia, new_zealand } from "./data";
// const prefix_key = (pre = 'au.') => ([k, v]) => [`${pre}${k}`, v.name]
export const OperatorList = { // Object.fromEntries([ // TODO compute this automatically from data.ts
    // ...Object.entries(australia).map(prefix_key('au.')),
    'au.apollo': 'Apollo',
    'au.britz': 'Britz',
    'au.maui': 'Maui',
    'au.mighty': 'Mighty',
    'au.camperman': `Camperman`,
    'au.cheapa': `Cheapa`,
    'au.hippie': `Hippie`,
    'au.jucy': `Jucy`,
    'au.letsgo': `Lets Go`,
    'au.spaceships': `Spaceships`,
    'au.star-rv': `Star RV`,
    'au.travellers-autobarn': 'Travellers Autobarn',
    // ...Object.entries(new_zealand).map(prefix_key('nz.')),
    'nz.apollo': `Apollo`,
    'nz.britz': `Britz`,
    'nz.maui': `Maui`,
    'nz.mighty': `Mighty`,
    'nz.cheapa': `Cheapa`,
    'nz.hippie': `Hippie`,
    'nz.jucy': `Jucy`,
    'nz.spaceships': `Spaceships`,
    'nz.star-rv': `Star RV`,
    'nz.travellers-autobarn': `Travellers Autobarn`,
    'nz.barefoot-campers': `Barefoot Campers`,
    'nz.biglittle': `Big Little`,
// ]) 
} as const

export const OperatorRawKeys = [
    'apollo',
    'britz',
    'maui',
    'mighty',
    'camperman',
    'cheapa',
    'hippie',
    'jucy',
    'letsgo',
    'spaceships',
    'star-rv',
    'travellers-autobarn',
    'barefoot-campers',
    'biglittle',
    'generic',
] as const

export const AllOperators = Object.fromEntries(Object.entries(OperatorList).map(([k,v])=>[k.split('.')[1], v])) as Record<typeof OperatorRawKeys[number], typeof OperatorList[keyof typeof OperatorList]>

export const OperatorsGeneric = Object.fromEntries(Object.entries(OperatorList).map(([k, v]) => [k.split('.')[1], v])) // TODO move to types and name properly
export const Operators = Object.keys(OperatorList) as Array<keyof typeof OperatorList>
export type OperatorKey = typeof OperatorRawKeys[number]
export type Operator = typeof Operators[number]
export const OperatorNames = tuple(...Object.values(OperatorList))
export type OperatorName = typeof Operators[number]

export function getOperatorByKey(l: string): { nice: string, slug: string, region: string } {
    let [region, slug] = [...l.split('.'), null]
    if (!region) return null
    if (!slug) {
        slug = region
        region = null
    }
    const nice = Object.entries(OperatorList).find(([k]) => k == l || k == slug)[1]
    return {
        nice,
        slug,
        region,
    }
}

// export const multipliers:Record<keyof typeof OperatorList,number> = { // Not to be confused with netFactor
// }

/*
//  █████╗ ██████╗ ██████╗ 
// ██╔══██╗██╔══██╗██╔══██╗
// ███████║██████╔╝██████╔╝
// ██╔══██║██╔═══╝ ██╔═══╝ 
// ██║  ██║██║     ██║     
// ╚═╝  ╚═╝╚═╝     ╚═╝     
*/

export const YYYYMMDD = (d:Date|string|number = Date.now()) => { // new Intl.DateTimeFormat('en-CA', { dateStyle: 'short', timeZone: 'Australia/Sydney' }).format(new Date(d))
    const D = new Date(d)
    const year = D.getFullYear();
    const month = String(D.getMonth() + 1).padStart(2, '0'); // Add 1 because 0-based months
    const day = String(D.getDate() + 1).padStart(2, '0'); // Add 1 because of timezones
    return [year,month,day].join('-')
}
export const YYYYMMDD_AEDT = (d:Date|string|number = Date.now()) => {
    const D = new Date(d)
    return D.toLocaleDateString('en-GB', { timeZone: "Australia/Sydney" }).split('/').reverse().join('-')
}
export const JucyDate = (d:Date|string|number = Date.now()) => new Intl.DateTimeFormat('en-CA', { dateStyle: 'short', timeZone: 'Australia/Sydney' }).format(new Date(d))

// const YYYYMMDD = (d:Date|string|number) => {
//     const D = new Date(d)
//     const lpad = (v: any,l=2,c=0,s=v=>`${v}`) => (s(c).repeat(l)+s(v)).slice(-Math.max(s(v).length, l))
//     return [D.getFullYear(), lpad(D.getMonth()), lpad(D.getDate())].join('-')
// }
export const DDMMYYYY = (d: Date) => d.toLocaleDateString('en-GB', { timeZone: "Australia/Sydney" })

export type Product = {
    id: number | string | null, // used by upstream APIs to identify products
    rp_id: string, // used in ZD field 6684723339161 to select product used in finalisation
    name: string,
    type: VehicleType,
    pax: number,
    bed: BedType,
    transmission: TransmissionOption,
    fridge: FridgeOption,
    amenity: AmenityOption,
    newness: NewnessOption,
    specs: string,
    boosterSeats: number,
    imagesLink: string,
    youtubeLink: string,
    internalDataLink: string,
}
export type InsurancePolicyOption = {
    id: number|string,
    name: string,
    fees: number,
    totalinsuranceamount: number,
    maximumprice: number,
    excessamount: number,
}
export type Option = Product & {
    provider_key: ProviderKey,
    fee: number,
    daily: number,
    discount_total: number,
    total: number,
    policies?: Array<InsurancePolicyOption>,
    id?: string|number, // from upstream API
    rp_id?: string, // from ZD
}
export type ProviderKey = Operator
export type ProviderName = OperatorName

export const sleep = (ms=1000) => new Promise(a=>setTimeout(a,ms))
export const ifNaN = (v=1, d=null) => isNaN(v) ? d : v

// ProviderOptions for S10 server side payment profiles

export type PaymentType = 'FULL_PAYMENT' | 'DEPOSIT'
export type CommissionMode = `NET_UP` | `GROSS_DOWN`
export type PaymentProfile = {
    dailyCommission: number,
    insuranceCommission?:number,
    extraCommission?:number,
    mode: CommissionMode,
    type: PaymentType,
}
export type DATA_SOURCE = (q: QueryConfiguration & { products: Product[] }, artifact?: Artifact) => Option[] | Promise<Option[]>

// For the ability to use the API directly to list products, in addition to being a DATA_SOURCE
export type SDK = {
    listAll: (pickup:Date, dropoff:Date, from:RPLocation, to:RPLocation, provider_key:Operator) => Option[] | Promise<Option[]>,
}

export type ProviderOptions = {
    API: DATA_SOURCE & SDK,
    profile: PaymentProfile,
    stripeProductId: string,
    // locations: Record<string, number>, // Map location slugs (au.gold_coast) to specific ID for the provider (1)
}

export const australia_locations = [
    'au.alice_springs',
    'au.brisbane',
    'au.melbourne',
    'au.perth',
    'au.darwin',
    'au.cairns',
    'au.sydney',
    `au.airlie_beach`,
    `au.gold_coast`,
    'au.hobart',
    'au.adelaide',
] as const
export const new_zealand_locations = [
    "nz.auckland",
    "nz.christchurch",
    "nz.queenstown",
] as const

export type RPLocationAU = typeof australia_locations[number] // 'au.alice_springs' | 'au.brisbane' | 'au.melbourne' | 'au.perth' | 'au.darwin' | 'au.cairns' | 'au.sydney' | `au.airlie_beach` | `au.gold_coast` | 'au.hobart' | 'au.adelaide'
// Alice Springs	ASP
// Broome	BME
// 'au.melbourne_airport': 'MAP', // Melbourne Airport / Australia
export type RPLocationNZ = typeof new_zealand_locations[number] // "nz.auckland" | "nz.christchurch" | "nz.queenstown"
export type RPLocation =  RPLocationAU | RPLocationNZ

// export const Operators = [ // use OperatorName
//     'apollo',
//     'barefoot-campers',
//     'biglittle',
//     'britz',
//     'camperman',
//     'cheapa',
//     'generic',
//     'hippie',
//     'jucy',
//     'letsgo',
//     'maui',
//     'mighty',
//     'spaceships',
//     'star-rv',
//     'travellers-autobarn',
// ] as const
type OperatorSlug = typeof Operators[number]

export type QueryConfiguration = {
    pickup:StateKey,
    dropoff:StateKey,
    pickup_date: Date,
    days: number,
    dropoff_date: Date,
    specs?,
    types,
    pax: number,
    discount_percent: number,
    provider_key: ProviderKey,
    insurance?: boolean,
}

export type AgentInfo = {
    id:number,
    email: string,
    name: string,
    avatar: string,
}
export type res_quote = {
    id:number,
    created_at: string,
    key: string,
    prepared_for: string,
    name: string,
    surname: string,
    title: string,
    reference: string,
    offer_duration: number,
    pax: number,
    operators: string[],
    options: any[],
    types: any[],
    discount_percent: number,
    discount_expiry: string,
    pickup: StateKey,
    dropoff: StateKey,
    pickup_date: string,
    dropoff_date: string,
    status: string,
    offers: Array<Option>,
    selection: any|null,
    selection_made:	any|null,
    by: AgentInfo,
    url: string,
}

export type res_error = {
    error: string,
    message?: string,
    location?: string,
    artifact?: string,
}
export type res_finalise_quote = {
    quote:res_quote,
    profiles:Record<string,PaymentProfile>
}

// For use with artifacts
export type Artifact = any
export const log = (a:Artifact,...v:any[]) => {
    console.log(...v);
    a.log = [...(a.log??[]), v]
}