import { Badge, Button, Loader, Menu, Pagination, Tooltip } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import 'keen-slider/keen-slider.min.css';
import { DAY, getLocationByKey, Option, StatusList } from 'ratpack-server/src/types';
import 'ratpack-ui/src/app/AppImports';
import { Anchor } from 'ratpack-ui/src/components/Anchor/Anchor';
import { Checkbox } from 'ratpack-ui/src/components/Checkbox/Checkbox';
import { Select } from 'ratpack-ui/src/components/Select/Select';
import { TextInput } from 'ratpack-ui/src/components/TextInput/TextInput';
import { AdminListQuotesPage } from 'ratpack-ui/src/pages/AdminListQuotesPage';
import { AdminPageLayout } from 'ratpack-ui/src/pages/AdminPageLayout';
import 'ratpack-ui/src/styles/Font.css';
import * as React from 'react';
import { useState } from 'react';
import { atom, selector, selectorFamily, useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { GET, POST } from '../api';
import { MustLogIn } from '../session';
import { DDMMYYYY, timeDifferenceSignificantParts, useTime } from '../time';
import { stateful } from '../util';
import { adminDefaults } from './components';
import { useMultiStateBool } from './createQuote';
import { AiOutlineSearch } from "react-icons/ai";
import { useDebouncedValue } from '@mantine/hooks';

export const cacheTimeAtom = atom({
    // Represents when the current session started caching things like quotes.
    // Can be used to request changes since then on page 0.
    key: 'cache_time',
    default: Date.now(),
})

export type Payment = {
    id: number,
    name: string,
    total: number,
}
export const paymentsAtom = selector<Array<Payment>>({
    key: 'payments',
    // get: async ({ get }) => await GET(`/api/quotes`),
    // get: async ({ get }) => (await Promise.all([...Array(10)].map((_, i) => GET(`/api/quotes?page=${i + 1}`)))).flat()
    get: async ({ get }) => [{
        name: 'example 1',
        total: 101_00,
    },{
        name: 'example 2',
        total: 102_00,
    }]
})

export const parsePaymentDetails = (payment:Payment) => {
    const {
        id,
        // key,
        // prepared_for,
        // name,
        // surname,
        // title,
        // reference,
        // offer_duration,
        // pax,
        // operators,
        // options,
        // types,
        // discount_percent,
        // discount_expiry,
        // pickup,
        // dropoff,
        // pickup_date,
        // dropoff_date,
        // status,
        // selection_made,
        // created_at,
        // by,
        // offers,
        name,
        total,
    } = payment

    // const pickupDate = new Date(pickup_date)
    // const dropoffDate = new Date(dropoff_date)
    // const days = 1 + Math.round((dropoffDate.getTime() - pickupDate.getTime()) / DAY)
    // const duration = days == 1 ? `1 day` : `${days} days`

    // const pickup_location = getLocationByKey(pickup)
    // const dropoff_location = getLocationByKey(dropoff)

    // return {
    //     ...payment,
    //     offers: offers as Option[],
    //     pickupDate,
    //     dropoffDate,
    //     days,
    //     duration,
    //     pickup_location,
    //     dropoff_location,
    // }
    return {
        id,
        name,
        total,
    }
}

export default () => {
    // const quotes = useRecoilValue(quotesAtom)
    const quotesLoad = useRecoilValueLoadable(paymentsAtom)
    const loadedQuotes = quotesLoad.state=='hasValue'
    const visibleQuotes = loadedQuotes ? quotesLoad.contents : []

    const refreshQuotes = useRecoilRefresher_UNSTABLE(paymentsAtom)
    const [loading, setLoading] = useState(false)
    const setStatus = (id, status) => {
        setLoading(true)
        POST(`/api/quote/${id}`, { status }).then(res => {
            refreshQuotes()
            setLoading(false)
        })
    }
    const setter = (v: any) => v
    const [statusFilter, useStatusFilter, validStatusFilter, setStatusFilter] = stateful(null, { setter })

    const [expanded, useExpanded, renderExpanded, setExpanded] = useMultiStateBool(visibleQuotes.map(q => q.key))
    const clipboard = useClipboard({ timeout: 2000 });
    const t = useTime(10e3)
    const [lastCopied, setLastCopied] = useState('')
    const [page, setPage] = useState(1)
    const QUOTES_PER_PAGE = 25
    const paginate = QUOTES_PER_PAGE < visibleQuotes.length

    const [searchValue, setSearchValue] = useState('')
    const [debouncedSearchValue] = useDebouncedValue(searchValue, 180)

    const [listResults, setListResults] = useState([])
    React.useEffect(() => {
        const matchSearchTerm = (item, terms) => {
            if (!terms || !terms.length) return true
            // console.log(item)
            const {
                //     id,
                //     key,
                //     prepared_for,
                //     name,
                //     surname,
                //     title,
                //     reference,
                //     offer_duration,
                //     pax,
                operators,
                options,
                types,
                //     discount_percent,
                //     discount_expiry,
                //     pickup,
                //     dropoff,
                //     pickup_date,
                //     dropoff_date,
                //     status,
                //     selection_made,
                //     created_at,
                by,
                offers,
                ...rest
            } = item
            return terms.toLowerCase().split(' ').every(term => {
                if (`${by?.name ?? ''}`.toLowerCase().includes(term)) return true
                if (`${by?.email ?? ''}`.toLowerCase().includes(term)) return true
                if (`${(operators ?? []).join(', ')}`.toLowerCase().includes(term)) return true
                if (`${(types ?? []).join(', ')}`.toLowerCase().includes(term)) return true
                if (`${(options ?? []).join(', ')}`.toLowerCase().includes(term)) return true
                const offerText = (offers ?? []).map((o: any) => (o?.name ?? '')).join(', ')
                if (`${offerText}`.toLowerCase().includes(term)) return true
                if (Object.values(rest).some(v => `${v}`.toLowerCase().includes(term))) return true
                return false
            })
        }
        console.log(`Filtering... ${statusFilter} ${debouncedSearchValue} ${visibleQuotes.length}`)
        const filteredQuotes = visibleQuotes
            .filter(({ status }) => statusFilter == 'all' || (statusFilter ? status.toLowerCase() == statusFilter : status.toLowerCase() != 'deleted'))
            .filter((item) => matchSearchTerm(item, searchValue))
            .sort((a, b) => (new Date(b.created_at).getTime() - new Date(a.created_at).getTime()))

        setListResults(filteredQuotes)
    }, [debouncedSearchValue, visibleQuotes, statusFilter])
    const quoteRows = (listResults).slice((page - 1) * QUOTES_PER_PAGE).slice(0, QUOTES_PER_PAGE).map(item => (<PaymentToRowItem {...{
        payment: item,
        expanded,
        useExpanded,
        setLastCopied,
        clipboard,
        lastCopied,
        setStatus,
}}))


    const [searchLoading, setSearchLoading] = useState(false)
    const [searchFocus, setSearchFocus] = useState(false)
    const bulkActionsSelect = [null, (
        <Select
            placeholder="Bulk actions"
            value={'bulk-action-1'}
            data={[
                { value: 'bulk-action-1', label: 'Bulk action #1' },
                { value: 'bulk-action-2', label: 'Bulk action #2' },
                { value: 'bulk-action-3', label: 'Bulk action #3' },
            ]}
        />
    ),
        <TextInput
            placeholder="Search"
            rightSection={searchLoading ? <Loader size="xs" /> : <AiOutlineSearch color="white" />}
            style={{
                transitionDuration: '800ms',
                transitionProperty: 'width',
                width: searchFocus ? '100%' : '200px',
            }}
            onFocus={e => setSearchFocus(true)}
            onBlur={e => setSearchFocus(false)}
            value={searchValue}
            onChange={e => setSearchValue(e.currentTarget.value)}
        />
    ][2]
    const applyButton = null // <Button styleType="white">Apply</Button>

    const showingFromNumber = ((page-1)*QUOTES_PER_PAGE) + (listResults.length ? 1 : 0)
    const leftContent = (listResults.length==0) ? `Showing 0 quotes` : `Showing ${showingFromNumber}-${Math.min(QUOTES_PER_PAGE, listResults.length)} of ${listResults.length} quotes`

    return (<AdminPageLayout {...adminDefaults({ page: 'list' })} page={<>
        <MustLogIn />
        <AdminListQuotesPage

            topControlsArea={AdminListQuotesPage.TopControlsArea({
                bulkActionsSelect,
                applyButton,
                statusSelect: (!loadedQuotes) ? 'Loading...' : (<>
                    <Select
                        placeholder="Filter by Status"
                        value={''}
                        clearable
                        searchable={false}
                        {...useStatusFilter}
                        data={Object.entries({ ...StatusList, All: 'all' }).map(([label, value]) => ({ value, label }))}
                    />
                </>),
                searchInput: null, // <TextInput placeholder="Search pages" />,
            })}
            tableRows={quoteRows}
            bottomArea={AdminListQuotesPage.BottomArea({
                leftContent,
                rightContent: paginate && <Pagination color="brandBlack" value={page} total={Math.ceil(listResults.length / QUOTES_PER_PAGE)} onChange={setPage} />,
            })}
        />
    </>} />)

}

export const PaymentToRowItem = ({
    payment,
    expanded,
    useExpanded,
    setLastCopied,
    clipboard,
    lastCopied,
    setStatus,
}) => {
    const {
        // id,
        // key,
        // prepared_for,
        // name,
        // surname,
        // title,
        // reference,
        // offer_duration,
        // pax,
        // operators,
        // options,
        // types,
        // discount_percent,
        // discount_expiry,
        // pickup,
        // dropoff,
        // pickup_date,
        // dropoff_date,
        // status,
        // selection_made,
        // created_at,
        // by,
        // days,
        // duration,
        // pickup_location,
        // dropoff_location,
        // url,
        id,
        name,
        total,
    } = parsePaymentDetails(payment)

    const [menuOpen, setMenuOpen] = useState(false);
    const statusColor = (status) => {
        status = (status ?? '').toLowerCase()
        const color = ({
            pending: 'blue',
            requote: 'yellow',
            expired: 'gray',
            booked: 'green',
            deleted: 'red',
        })[status] ?? 'gray'
        return color
    }

    return <AdminListQuotesPage.TableRow
        key={id}
        {...({
            extraControls: null,
            status: <Badge color={statusColor(status)} variant="filled">{status}</Badge>,

            expanded: expanded[key],
            onClick: useExpanded(key).onChange,
            checkbox: <Checkbox />,
            bookingRef: <Tooltip
                opened={clipboard.copied && lastCopied == key}
                label="Copied!"
                withArrow
            >
                <Badge color={statusColor(status)} variant="dot" onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                    e.stopPropagation()
                    setLastCopied(key)
                    clipboard.copy(reference)
                }}>{reference}</Badge>
            </Tooltip>,
            name: `${name} ${surname}`,
            quoteDate: `${DDMMYYYY(created_at)}`,
            agent: by?.name,
            // <a href="https://bit.ly/RatPack-Travel-FaceBook" target="_blank" rel="noreferrer">

            // urlLink: <Anchor styleType="basic" target="_blank" rel="noreferrer" href={`/qt/${key}`} onClick={(e) => e.stopPropagation()} >Link</Anchor>,
            urlLink: <Anchor styleType="basic" target="_blank" rel="noreferrer" href={url??`https://bookings.ratpacktravel.com/q/${key}`} onClick={(e) => e.stopPropagation()} >Link</Anchor>,
            description: title,
            offerExpires: timeDifferenceSignificantParts(new Date(discount_expiry), 2, '<1m', 'Expired'),
            actionButtons: (<Menu
                opened={menuOpen}
                // onChange={setMenuOpen}
                onClick={e => e.stopPropagation() + setMenuOpen(v => !v)}
            >

                <Menu.Target>
                    <Button onClick={()=>setMenuOpen(v => !v)} color="brandBlack">...</Button>
                </Menu.Target>

                <Menu.Dropdown>
                    <Menu.Label>Set status</Menu.Label>
                    {Object.entries(StatusList).map(([nice, slug]) => {
                        const extra = {} as any
                        if (slug == 'deleted') extra.color = 'red'
                        return <Menu.Item key={slug} {...extra} onClick={() => setStatus(id, slug)}>{nice}</Menu.Item>
                    })}
                </Menu.Dropdown>
            </Menu>),
            firstDetailTableRows: [
                // AdminListQuotesPage.TableDetailsRow({
                //     label: 'Phone',
                //     value: '61 421587126',
                // }),
                // AdminListQuotesPage.TableDetailsRow({
                //     label: 'Email',
                //     value: 'alessandrobuchanan@email.com',
                // }),
                AdminListQuotesPage.TableDetailsRow({ label: 'Discount', value: `${discount_percent}%` }),
                AdminListQuotesPage.TableDetailsRow({ label: 'Record No.', value: id }),
                AdminListQuotesPage.TableDetailsRow({ label: 'Record Key', value: key }),
                AdminListQuotesPage.TableDetailsRow({ label: 'Agent', value: by?.name }),
            ],
            secondDetailTableRows: [
                AdminListQuotesPage.TableDetailsRow({
                    label: 'Pickup location',
                    value: pickup_location.nice,
                }),
                AdminListQuotesPage.TableDetailsRow({
                    label: 'Drop off location',
                    value: dropoff_location.nice,
                }),
                AdminListQuotesPage.TableDetailsRow({
                    label: 'Start Date',
                    value: DDMMYYYY(pickup_date),
                }),
                AdminListQuotesPage.TableDetailsRow({
                    label: 'End Date',
                    value: DDMMYYYY(dropoff_date),
                }),
                AdminListQuotesPage.TableDetailsRow({
                    label: 'Duration',
                    value: duration,
                }),
            ],
        })}
    />
}