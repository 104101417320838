import { Avatar, Image, Space } from '@mantine/core';
import 'keen-slider/keen-slider.min.css';
import 'ratpack-ui/src/app/AppImports';
import { Anchor } from 'ratpack-ui/src/components/Anchor/Anchor';
import { Text } from 'ratpack-ui/src/components/Text/Text';
import { AdminPageLayout } from 'ratpack-ui/src/pages/AdminPageLayout';
import 'ratpack-ui/src/styles/Font.css';
import * as React from 'react';
import { Link } from "react-router-dom";

import iconBus from '../assets/bus.png'
import { useRecoilValue } from 'recoil';
import { userAtom } from '../session';
import { niceDate } from '../time';
import availability from './availability';

export const home = '/search'
export const login = '/login'



export const activeColorBind = hash => (url, yes = "Yellow", no = "White") => hash.startsWith(url) ? 'Yellow' : 'White'

export const header = ({
    page = 'list' as 'list' | 'create' | 'settings' | 'availability' | 'payments',
}) => {
    const user = useRecoilValue(userAtom)
    console.log({ user })
    return (<AdminPageLayout.Header
        leftContent={(
            <div style={{ display: 'flex', alignItems: 'center', width:'100%'}}>
                <Image src={iconBus} height={40} width={'auto'}  />
                <Space w={40} />
                <div style={{ display: 'flex' }}>
                    <Link to="/admin/quotes"><Anchor
                        styleType="black"
                        style={{ fontWeight: page == 'list' ? 600 : 400 }}
                    >
                        Search Results
                    </Anchor></Link>
                    <Space w={24} />
                    <Link to="/admin/create-quote"><Anchor
                        styleType="black"
                        style={{ fontWeight: page == 'create' ? 600 : 400 }}
                    >
                        Search
                    </Anchor></Link>
                    <Space w={24} />
                    <Link to="/admin/settings"><Anchor
                        styleType="black"
                        style={{ fontWeight: page == 'settings' ? 600 : 400 }}
                    >
                        Settings
                    </Anchor></Link>
                    <Space w={24} />
                    <Link to="/admin/availability"><Anchor
                        styleType="black"
                        style={{ fontWeight: page == 'availability' ? 600 : 400 }}
                    >
                        Availability
                    </Anchor></Link>
                    <Space w={24} />
                    {(window.location.hostname=='local.host') && <Link to="/admin/payments"><Anchor
                        styleType="black"
                        style={{ fontWeight: page == 'payments' ? 600 : 400 }}
                    >
                        Payments
                    </Anchor></Link>}
                </div>
            </div>
        )}
        rightContent={(
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar src={user?.avatar ?? ''} size={56} radius={999} />
                <Space w={20} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>{user?.name}</Text>
                    <Anchor component={Link} styleType="gray" to="/logout">
                        Log out
                    </Anchor>
                    <Text size='xs'>{niceDate()}</Text>
                </div>
            </div>
        )}
    />)
}
export const adminDefaults = ({
    page = 'list' as 'list' | 'create' | 'settings' | 'availability' | 'finalise' | 'payments',
}) => ({ header: header({ page }) })
